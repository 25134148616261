module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KounterTop","short_name":"KounterTop","start_url":"/","background_color":"#ffffff","theme_color":"#009688","display":"browser","icon":"src/images/KounterTop_Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"790f811c2727985f31347b0088d854a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"colorPrimary":"#009688","colorPrimaryHalfOpacity":"rgba(0, 150, 136, 0.5)","colorPrimaryLowestOpacity":"rgba(0, 150, 136, 0.1)","colorSecondary":"#F19C00","colorSubtitle":"rgb(102, 102, 102)","fontSizeSmall":"0.8rem","fontSizeNormal":"16px","fontSizeMedium":"1.5rem","fontSizeLarge":"2rem"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142564066-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
